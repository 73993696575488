<template>
  <b-card>
    <b-tabs card>
      <b-tab id="application" title="Application">
        <staff-application-documents />
      </b-tab>
      <b-tab id="visa" title="Visa">
        <staff-visa-documents />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import StaffApplicationDocuments from "./Components/StaffApplicationDocuments.vue";
import StaffVisaDocuments from "./Components/StaffVisaDocuments.vue";

export default {
  components: {
    BCard,
    StaffApplicationDocuments,
    StaffVisaDocuments,
    BTab,
    BTabs,
  },
  data() {
    return {};
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        return type;
      } else {
        return null;
      }
    },
  },
  methods: {},
};
</script>