export const all_months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const budgets_old = [
    { label: "1,00,000", value: 100000 },
    { label: "3,00,000", value: 300000 },
    { label: "5,00,000", value: 500000 },
    { label: "10,00,000", value: 1000000 },
    { label: "40,00,000", value: 4000000 },
];

export const budgets = [

    {
        label: "Under 6.80 Lakhs",
        value: `[null, 680000]`
    },
    {
        label: "7 Lakhs - 14 Lakhs",
        value: `[700000, 1400000]`
    },
    {
        label: "15 Lakhs - 23 Lakhs",
        value: `[1500000, 2300000]`
    },
    {
        label: "24+ Lakhs",
        value: `[2400000, null]`
    }
]

export const ug_docs = [
    { doc_id: 1, doc_name: "PASSPORT", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 23, doc_name: "Resume", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 24, doc_name: "Aadhar Card/ Driving License/ Elector Card", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 27, doc_name: "9th Grade Certificate", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 3, doc_name: "SSC (10th) Certificate", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 28, doc_name: "11th Grade Certificate", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 4, doc_name: "HSC (12th) Certificate", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 17, doc_name: "IELTS/ TOEFL Test Score", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 29, doc_name: "SAT / ACT", doc_url: null, doc_file: null, is_mandatory: false }
];

export const pg_docs = [
    { doc_id: 1, doc_name: "PASSPORT", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 23, doc_name: "Resume", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 24, doc_name: "Aadhar Card/ Driving License/ Elector Card", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 3, doc_name: "SSC (10th) Certificate", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 4, doc_name: "HSC (12th) Certificate", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 11, doc_name: "Degree Marksheet –All semesters including all KT mark sheets", doc_url: null, doc_file: null, is_mandatory: true },
    { doc_id: 9, doc_name: "Degree Provisional Certificate", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 10, doc_name: "Degree Certificate", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 14, doc_name: "Work Experience –Appointment Letter", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 19, doc_name: "Letter of Recommendations (LOR)", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 25, doc_name: "SOP / Motivational Letter", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 26, doc_name: "GRE/ GMAT", doc_url: null, doc_file: null, is_mandatory: false },
    { doc_id: 17, doc_name: "IELTS/ TOEFL Test Score", doc_url: null, doc_file: null, is_mandatory: false }
];

export const getAllYears = () => {
    const currentYear = new Date().getFullYear();
    let all_years = [];
    for (let i = 0; i < 10; i++) {
        all_years.push(currentYear + i);
    }
    return all_years
}
